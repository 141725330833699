import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { generateAlumniEmbedCode } from '../../../helpers/widget-embed-code-utils';
import {
  AlumniWidgetBuilderPreview,
  AlumniWidgetBuilderSidebar,
  Layout,
  WidgetBuilderTabHeader,
  WidgetBuilderEmbed
} from 'components';

const Wrapper = styled.div`
  max-width: 125rem;
  margin: 0 auto;
  padding: 0 2rem;
  padding-top: 2.5rem;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  padding: 4rem 0 2.5rem 2rem;
  border-radius: 6px;
  width: 100%;
  overflow: hidden;

  @media (min-width: 740px) {
    flex-direction: row;
  }
`;

const WidgetBuilderOutput = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 80rem;
  width: 100%;
  overflow-x: auto;
`;

const EmbedWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const AlumniWidgetBuilder = ({ siteSettings }) => {
  const { currentSite, primaryColor } = siteSettings;

  const [configurations, setConfigurations] = useState({
    accordionHeadingText: '',
    accordionType: 'skills',
    carouselHeadingText: 'Working For These Companies',
    clientId: currentSite,
    geographyLevel: 'nation',
    graduationTimeframeStart: '1999', // will be condensed in to `graduationTimeframe`
    graduationTimeframeEnd: 'current', // will be condensed in to `graduationTimeframe`
    hasAccordion: true,
    hasOverview: true,
    hasCarousel: true,
    hasSalaryRange: true,
    hasSalary: true, // will be condensed in to `overview`
    hasEmployedInField: true, // will be condensed in to `overview`
    hasSubsequentAttainment: true, // will be condensed in to `overview`
    headingText: 'Wiggety Wiggety Widgets!',
    isOnlyAfterGraduation: false,
    overviewHeadingText: 'Overview',
    programs: '',
    primaryColor: primaryColor,
    selectedCampus: 'none',
    selectedDegreeLevel: 'none',
    selectedOccupations: '',
    selectedCompanies: '',
    selectedSkills: '',
    selectedGeography: '0',
    subsequentAttainmentLevel: 'bachelors'
  });

  const [isPreview, setIsPreview] = useState(true);

  return (
    <Layout title="Alumni Pathways Widget Builder">
      <Wrapper>
        <Card>
          <AlumniWidgetBuilderSidebar
            configurations={configurations}
            siteSettings={siteSettings}
            setConfigurations={setConfigurations}
          />
          <WidgetBuilderOutput>
            <WidgetBuilderTabHeader
              configurations={configurations}
              isPreview={isPreview}
              setIsPreview={setIsPreview}
              siteSettings={siteSettings}
            />
            {!isPreview && (
              <EmbedWrapper>
                <WidgetBuilderEmbed
                  embedCode={generateAlumniEmbedCode({
                    clientId: currentSite,
                    configurations
                  })}
                />
              </EmbedWrapper>
            )}
            <AlumniWidgetBuilderPreview
              isHidden={!isPreview}
              configurations={configurations}
              siteSettings={siteSettings}
            />
          </WidgetBuilderOutput>
        </Card>
      </Wrapper>
    </Layout>
  );
};

AlumniWidgetBuilder.propTypes = {
  siteSettings: PropTypes.shape({
    nation: PropTypes.string.isRequired
  })
};

export default AlumniWidgetBuilder;
