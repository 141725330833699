import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { locale } from '../../../helpers/locale';
import { Card, ContentWrap, Layout, WidgetLinkCard } from 'components';

const StyledCard = styled(Card)`
  margin-top: 2rem;
  padding-right: 0;
`;

const CardHeader = styled.h2`
  font-size: 22px;
  font-weight: 600;
`;

const WidgetCardsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (min-width: 760px) {
    justify-content: flex-start;
  }
`;

const WidgetBuilderDashboard = ({
  isAlumniWidgetEnabled,
  isAssessmentWidgetEnabled,
  nation,
  isProgramWidgetEnabled
}) => {
  return (
    <Layout title="Widget Builder">
      <ContentWrap>
        <StyledCard>
          <CardHeader>Select Widget Type</CardHeader>
          <WidgetCardsWrapper>
            {isAlumniWidgetEnabled && (
              <WidgetLinkCard
                to="/widget-builder/alumni"
                description="Data on the career and educational outcomes for your Alumni"
                headerText="Alumni Widget"
              />
            )}
            {isAssessmentWidgetEnabled && (
              <WidgetLinkCard
                to="/widget-builder/assessment"
                description={`RIASEC assessment workflow leading to career and ${locale.edTrack[
                  nation
                ].toLowerCase()} discovery`}
                headerText="Assessment Widget"
              />
            )}
            {isProgramWidgetEnabled && (
              <WidgetLinkCard
                to="/widget-builder/program"
                description={`Data on the career outcomes for your ${locale.edTrack[nation]}`}
                headerText={`${locale.edTrack[nation]} Widget`}
              />
            )}
          </WidgetCardsWrapper>
        </StyledCard>
      </ContentWrap>
    </Layout>
  );
};

WidgetBuilderDashboard.propTypes = {
  isAlumniWidgetEnabled: PropTypes.bool.isRequired,
  isAssessmentWidgetEnabled: PropTypes.bool.isRequired,
  isProgramWidgetEnabled: PropTypes.bool.isRequired,
  nation: PropTypes.string.isRequired
};

export default WidgetBuilderDashboard;
