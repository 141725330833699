import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactSelect from 'react-select';
import { HorizontalRule, WidgetBuilderToggle } from 'components';

const Sidebar = styled.div`
  min-width: 35rem;
  padding: 0 2rem 2rem 1rem;
  border-right: 2px solid #f0f1f3;
  overflow-y: auto;
`;

const Header = styled.h2`
  margin-bottom: 1.5rem;
  font-size: 2.2rem;
  font-weight: 700;
`;

const Label = styled.label`
  margin: 0 0 2rem 0;
  font-weight: 600;
`;

const Select = styled(ReactSelect)`
  font-weight: 400;
`;

const AlumniWidgetBuilderSidebar = props => {
  const { configurations, siteSettings, setConfigurations } = props;
  const { aConfigurtion = false, primaryColor } = configurations;
  const { currentSite } = siteSettings;

  return (
    <Sidebar>
      <Header>Select Alumni Data</Header>
      <WidgetBuilderToggle
        id="just-a-lil-toggle"
        label="This Does Nothing"
        handleChange={() => setConfigurations({ ...configurations, aConfigurtion: !aConfigurtion })}
        isChecked={aConfigurtion}
      />
      <HorizontalRule />
    </Sidebar>
  );
};

AlumniWidgetBuilderSidebar.propTypes = {
  configurations: PropTypes.shape({
    primaryColor: PropTypes.string
  }).isRequired,
  currentSite: PropTypes.string,
  setConfigurations: PropTypes.func.isRequired,
  siteSettings: PropTypes.shape({}).isRequired
};

export default AlumniWidgetBuilderSidebar;
